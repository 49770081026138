exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-illustrations-js": () => import("./../../../src/pages/illustrations.js" /* webpackChunkName: "component---src-pages-illustrations-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-open-source-js": () => import("./../../../src/pages/open-source.js" /* webpackChunkName: "component---src-pages-open-source-js" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2012-academia-vs-industry-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2012/academia-vs-industry.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2012-academia-vs-industry-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2012-bricolage-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2012/bricolage.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2012-bricolage-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2012-cockpit-system-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2012/cockpit-system.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2012-cockpit-system-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2012-epistemic-rotation-tetris-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2012/epistemic-rotation-tetris.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2012-epistemic-rotation-tetris-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2012-learnability-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2012/learnability.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2012-learnability-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2012-lonely-networks-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2012/lonely-networks.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2012-lonely-networks-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2012-scent-of-information-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2012/scent-of-information.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2012-scent-of-information-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2012-usability-analysis-complex-domains-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2012/usability-analysis-complex-domains.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2012-usability-analysis-complex-domains-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2012-usabitliy-analysis-evolve-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2012/usabitliy-analysis-evolve.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2012-usabitliy-analysis-evolve-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2013-001-paper-prototyping-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2013/001-paper-prototyping.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2013-001-paper-prototyping-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2013-002-the-paradox-of-choice-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2013/002-the-paradox-of-choice.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2013-002-the-paradox-of-choice-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2013-003-evaluating-your-own-prototypes-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2013/003-evaluating-your-own-prototypes.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2013-003-evaluating-your-own-prototypes-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2013-004-information-evolution-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2013/004-information-evolution.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2013-004-information-evolution-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2013-005-ux-designers-code-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2013/005-ux-designers-code.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2013-005-ux-designers-code-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2013-006-ux-error-pages-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2013/006-ux-error-pages.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2013-006-ux-error-pages-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2013-007-prototypers-architects-of-web-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2013/007-prototypers-architects-of-web.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2013-007-prototypers-architects-of-web-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2014-001-hands-on-usability-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2014/001-hands-on-usability.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2014-001-hands-on-usability-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2014-002-user-engagement-through-disengagement-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2014/002-user-engagement-through-disengagement.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2014-002-user-engagement-through-disengagement-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2014-003-ux-maslows-pyramid-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2014/003-ux-maslows-pyramid.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2014-003-ux-maslows-pyramid-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2014-004-qna-model-ui-design-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2014/004-qna-model-ui-design.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2014-004-qna-model-ui-design-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-aug-aug-04-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/aug/aug-04.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-aug-aug-04-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-aug-aug-10-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/aug/aug-10.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-aug-aug-10-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-aug-aug-15-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/aug/aug-15.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-aug-aug-15-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-aug-aug-26-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/aug/aug-26.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-aug-aug-26-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-dec-dec-28-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/dec/dec-28.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-dec-dec-28-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-dec-dec-31-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/dec/dec-31.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-dec-dec-31-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jul-jul-05-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/jul/jul-05.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jul-jul-05-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jul-jul-08-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/jul/jul-08.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jul-jul-08-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jul-jul-17-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/jul/jul-17.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jul-jul-17-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jul-jul-22-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/jul/jul-22.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jul-jul-22-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jul-jul-26-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/jul/jul-26.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jul-jul-26-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jul-jul-next-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/jul/jul-next.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jul-jul-next-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jul-todo-article-events-on-the-ui-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/jul/TODO-article-events-on-the-ui.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jul-todo-article-events-on-the-ui-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jul-todo-article-fav-youtube-channels-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/jul/TODO-article-fav-youtube-channels.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jul-todo-article-fav-youtube-channels-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jun-jun-05-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/jun/jun-05.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jun-jun-05-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jun-jun-06-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/jun/jun-06.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jun-jun-06-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jun-jun-07-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/jun/jun-07.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jun-jun-07-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jun-jun-10-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/jun/jun-10.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jun-jun-10-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jun-jun-17-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/jun/jun-17.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jun-jun-17-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jun-jun-20-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/jun/jun-20.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jun-jun-20-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jun-jun-24-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/jun/jun-24.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jun-jun-24-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jun-jun-30-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/jun/jun-30.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-jun-jun-30-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-01-setting-up-gatsby-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/may/01-setting-up-gatsby.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-01-setting-up-gatsby-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-02-week-may-17-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/may/02-week-may-17.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-02-week-may-17-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-03-postgres-db-commands-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/may/03-postgres-db-commands.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-03-postgres-db-commands-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-04-finance-rails-axios-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/may/04-finance-rails-axios.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-04-finance-rails-axios-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-05-physics-gatsby-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/may/05-physics-gatsby.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-05-physics-gatsby-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-06-gatsby-js-piano-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/may/06-gatsby-js-piano.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-06-gatsby-js-piano-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-07-libraries-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/may/07-libraries.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-07-libraries-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-08-colors-ruby-music-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/may/08-colors-ruby-music.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-08-colors-ruby-music-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-09-gatsby-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/may/09-gatsby.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-09-gatsby-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-10-svg-css-colors-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/may/10-svg-css-colors.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-10-svg-css-colors-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-11-svg-notes-libraries-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/may/11-svg-notes-libraries.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-may-11-svg-notes-libraries-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-nov-nov-01-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/nov/nov-01.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-nov-nov-01-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-nov-nov-06-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/nov/nov-06.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-nov-nov-06-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-oct-oct-22-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/oct/oct-22.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-oct-oct-22-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-sep-sep-07-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2020/sep/sep-07.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2020-sep-sep-07-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2021-jan-dataviz-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2021/jan/dataviz.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2021-jan-dataviz-mdx" */),
  "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2023-pagination-using-urql-mdx": () => import("./../../../src/templates/article.tmpl.js?__contentFilePath=/vercel/path0/src/articles/2023/pagination-using-urql.mdx" /* webpackChunkName: "component---src-templates-article-tmpl-js-content-file-path-vercel-path-0-src-articles-2023-pagination-using-urql-mdx" */),
  "component---src-templates-articles-year-tmpl-js": () => import("./../../../src/templates/articles.year.tmpl.js" /* webpackChunkName: "component---src-templates-articles-year-tmpl-js" */)
}

